//
// Bulk Mail Controller
//
// Useful articles for the `insertToken` method:
//   https://kubyshkin.name/posts/insert-text-into-textarea-at-cursor-position/
//

import { Controller } from '@hotwired/stimulus'

export class BulkMailController extends Controller {
  static targets = ['content']

  // MARK: - Actions

  insertToken(event) {
    event.preventDefault()

    if (!this.hasContentTarget) return

    this.contentTarget.focus()
    document.execCommand('insertText', false, event.target.innerText)
  }
}
