//
// Activity Overlay
//

import { ElementScrollSuspension } from './element_scroll_suspension'

export class ActivityOverlay {
  // MARK: - Object Lifecycle

  constructor() {
    this._overlayElement = document.createElement('div')
    this._overlayElement.classList.add('tint-overlay')
    this._overlayElement.classList.add('tint-overlay--hide')

    this._spinnerElement = document.createElement('div')
    this._spinnerElement.classList.add('spinner')
    this._overlayElement.appendChild(this._spinnerElement)

    this._elementScrollSuspension = new ElementScrollSuspension()

    for (let i = 1; i < 4; i++) {
      const dotElement = document.createElement('div')
      dotElement.classList.add(`bounce${i}`)
      this._spinnerElement.appendChild(dotElement)
    }

    document.body.appendChild(this._overlayElement)
  }

  destroy() {
    this._overlayElement.remove()
    this._overlayElement = null
  }

  // MARK: - Overlay

  get overlayElement() {
    return this._overlayElement
  }

  // MARK: - Visibility

  get isVisible() {
    return this.overlayElement.classList.indexOf('tint-overlay--hide') < 0
  }

  setVisible(visible) {
    if (visible) {
      this._elementScrollSuspension.suspend()
      this.overlayElement.classList.remove('tint-overlay--hide')
    } else {
      this._elementScrollSuspension.unsuspend()
      this.overlayElement.classList.add('tint-overlay--hide')
    }
  }

  get isSpinnerVisible() {
    return this._spinnerElement.classList.indexOf('d-none') < 0
  }

  setSpinnerVisible(visible) {
    if (visible) {
      this._spinnerElement.classList.remove('d-none')
    } else {
      this._spinnerElement.classList.add('d-none')
    }
  }
}
