//
// Debounce
//

export class Debounce {
  // MARK: - Object Lifecycle

  constructor(delay) {
    this.delay = delay
  }

  // MARK: - Debouncing

  perform(callback) {
    this.cancel()
    this.timeout = setTimeout(() => {
      delete this.timeout
      callback()
    }, this.delay)
  }

  cancel() {
    if (!this.timeout) return

    clearTimeout(this.timeout)
    delete this.timeout
  }
}
