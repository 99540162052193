//
// Company Information Controller
//

import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js'
import { ajax } from '../lib/ajax'
import { Debounce } from '../lib/debounce'

export class CompanyInformationController extends Controller {
  static targets = ['addressCountryId', 'vatNumber', 'vatNumberInput', 'vatStatus']

  // MARK: - Object Lifecycle

  initialize() {
    this.debounce = new Debounce(500)
  }

  // MARK: - Getters

  get vatCountryIds() {
    return this.data.get('vat-country-ids').split(',')
  }

  // MARK: - Callbacks

  connect() {
    if (this.hasAddressCountryIdTarget) {
      this.choices = new Choices(this.addressCountryIdTarget, { allowHTML: false, itemSelectText: '' })
      this.updateVATVisibility()
    }
  }

  disconnect() {
    if (this.choices) this.choices.prepareForTurboCache()
    this.clearVATStatus()
  }

  // MARK: - Actions

  updateVATVisibility() {
    // Hide the VAT number field unless a country that requires VAT is selected.
    const selectedCountryId = this.addressCountryIdTarget.querySelector('option:checked').value
    this.vatNumberTarget.hidden = this.vatCountryIds.includes(selectedCountryId) == false
  }

  checkVAT() {
    this.debounce.perform(() => {
      if (this.isCheckingVAT) return

      this.clearVATStatus()

      const number = this.vatNumberInputTarget.value
      if (typeof number !== 'string' || number === '') return

      this.isCheckingVAT = true

      ajax('GET', '/api/v1/vat', { number: number })
        .then((result) => {
          if (result && result.data) {
            if (result.data.valid) {
              this.vatStatusTarget.classList.add('text--success')
            } else {
              this.vatStatusTarget.classList.add('text--danger')
            }

            this.vatStatusTarget.innerText = result.data.message

            if (result.data.name) {
              this.vatStatusTarget.innerText += ` ${result.data.name}`
            }
          }
        })
        .finally(() => {
          this.isCheckingVAT = false

          // If the VAT number has been changed since the request started, validate it again.
          if (this.vatNumberInputTarget.value != number) this.checkVAT()
        })
    })
  }

  clearVATStatus() {
    const errorElement = this.vatNumberTarget.querySelector('.error_message')
    if (errorElement) errorElement.remove()

    this.vatStatusTarget.innerText = ''
    this.vatStatusTarget.classList.remove('text--success')
    this.vatStatusTarget.classList.remove('text--danger')
  }
}
