//
// Job Listing Configuration Controller
//

import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js'

export class JobListingConfigurationController extends Controller {
  static targets = ['locationKind', 'remoteOptions', 'onsiteOptions', 'countryIds', 'states', 'stateIds']

  // MARK: - Callbacks

  connect() {
    // Replace the standard <select> elements with choices.js styled ones.
    this.choices = [
      new Choices(this.locationKindTarget, {
        allowHTML: false,
        shouldSort: false,
        searchEnabled: false,
        itemSelectText: '',
      }),
      new Choices(this.countryIdsTarget, { allowHTML: false, removeItemButton: true, itemSelectText: '' }),
      new Choices(this.stateIdsTarget, { allowHTML: false, removeItemButton: true, itemSelectText: '' }),
    ]

    this.didChangeLocationKind()
    this.didChangeCountries()
  }

  disconnect() {
    this.choices.forEach((choices) => choices.prepareForTurboCache())
  }

  // MARK: - Actions

  didChangeLocationKind() {
    // Show the onsite options when choosing a preference that includes physical attendance
    const onsiteOptions = ['all', 'onsite_only']
    this.onsiteOptionsTarget.hidden = onsiteOptions.includes(this.locationKindTarget.value) == false

    // Show the remote options when choosing a preference that includes virtual attendance
    const remoteOptions = ['all', 'remote_only']
    this.remoteOptionsTarget.hidden = remoteOptions.includes(this.locationKindTarget.value) == false
  }

  didChangeCountries() {
    // Get all selected countries, as an array of their values
    const selectedOptions = this.countryIdsTarget.querySelectorAll('option:checked')
    const selectedCountries = Array.from(selectedOptions).map((element) => element.value)

    // Show or hide the state field depending on whether USA is included
    this.statesTarget.hidden = selectedCountries.includes('USA') == false
  }
}
