//
// Rails <-> Promise / AJAX Helper
//

import Rails from '@rails/ujs'

export function convertToFormData(params) {
  if (typeof params !== 'object' || params === null) return null

  let data = new FormData()

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => data.append(`${key}[]`, item))
    } else {
      data.append(key, value)
    }
  })

  return data
}

export function parameterizeURL(url, params) {
  if (typeof url !== 'string' || url.length < 1) return url

  const data = convertToFormData(params)
  if (!data) return url

  const query = [...data].map(([k, v]) => `${k}=${v}`).join('&')
  return `${url}?${query}`
}

export function ajax(method, url, params = null, data = null) {
  return new Promise((resolve, reject) => {
    Rails.ajax({
      url: parameterizeURL(url, params),
      type: method,
      data: data,
      dataType: 'json',
      success(data, statusText, request) {
        resolve({ data: data, request: request, statusText: statusText })
      },
      error(response, statusText, request) {
        const error = new Error('HTTP Request Error (' + request.status.toString() + ') - ' + statusText)
        error.request = request
        error.response = response
        reject(error)
      },
    })
  })
}
