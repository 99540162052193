// This file is automatically compiled by esbuild, along with any other files present in this directory.
// You're encouraged to place your actual application logic in a relevant structure within app/javascript and only use
// these entry points to reference that code so it'll be compiled.

// Turbo
import '@hotwired/turbo'

// Rails
import Rails from '@rails/ujs'
Rails.start()

// Packages
import 'choices.js'

// Application specific
import './application/controllers'
import './application/patches/choices'
import './application/smooth_scrolling'
