//
// Dashboard Controller
//

import { Controller } from '@hotwired/stimulus'

export class DashboardController extends Controller {
  static targets = ['jobListingsCopy']

  // MARK: - Actions

  copyJobListingsText() {
    this.jobListingsCopyTarget.select()
    this.jobListingsCopyTarget.setSelectionRange(0, 9999999999)
    document.execCommand('copy')
  }
}
