//
// Smooth Scrolling
//

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

document.addEventListener('click', (event) => {
  if (!event.target.matches('a[href^="#"]')) return

  const hash = event.target.hash
  if (hash === '' || hash === '#') return

  const targetElement = document.querySelector(hash)
  if (!targetElement) return

  event.preventDefault()
  targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
})
