//
// Manage Job Listing Controller
//

import { Controller } from '@hotwired/stimulus'

export class ManageJobListingController extends Controller {
  static targets = ['rejectionForm', 'rejectionReason']

  // MARK: - Callbacks

  connect() {
    if (this.hasRejectionFormTarget) this.rejectionFormTarget.style.display = 'none'
  }

  // MARK: - Actions

  toggleRejectionForm() {
    if (!this.hasRejectionFormTarget) return

    if (this.rejectionFormTarget.style.display === 'none') {
      this.rejectionFormTarget.style.display = ''
    } else {
      this.rejectionFormTarget.style.display = 'none'
    }
  }

  submitRejection(event) {
    event.preventDefault()
    event.stopPropagation()

    const reason = this.rejectionReasonTarget.value || ''
    const result =
      reason.length > 0
        ? confirm('Are you sure you want to reject this job?')
        : confirm('Are you sure you want to reject this job without a reason? No email will be sent.')

    if (result) this.rejectionFormTarget.submit()
  }
}
