//
// Edit Job Listing Controller
//

import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js'

export class EditJobListingController extends Controller {
  static targets = [
    'employmentTerm',
    'employmentType',
    'locationKind',
    'remoteOptions',
    'onsiteOptions',
    'countryIds',
    'stateIds',
    'states',
    'description',
    'descriptionCounter',
    'socialText',
    'socialTextCounter',
  ]

  // MARK: - Callbacks

  connect() {
    // Replace the standard <select> elements with choices.js styled ones.
    this.choices = [
      new Choices(this.locationKindTarget, {
        allowHTML: false,
        shouldSort: false,
        searchEnabled: false,
        itemSelectText: '',
      }),
      new Choices(this.countryIdsTarget, { allowHTML: false, removeItemButton: true, itemSelectText: '' }),
      new Choices(this.stateIdsTarget, { allowHTML: false, removeItemButton: true, itemSelectText: '' }),
    ]

    if (this.hasEmploymentTermTarget) {
      this.choices.push(
        new Choices(this.employmentTermTarget, {
          allowHTML: false,
          shouldSort: false,
          searchEnabled: false,
          itemSelectText: '',
        })
      )
    }

    if (this.hasEmploymentTypeTarget) {
      this.choices.push(
        new Choices(this.employmentTypeTarget, {
          allowHTML: false,
          shouldSort: false,
          searchEnabled: false,
          itemSelectText: '',
        })
      )
    }

    this.updateLocationOptionsVisibility()
    this.updateStatesVisibility()
    this.descriptionDidChange()
    this.socialTextDidChange()
    this.scrollToFirstError()
  }

  disconnect() {
    this.choices.forEach((choices) => choices.prepareForTurboCache())
  }

  // MARK: - Actions

  updateLocationOptionsVisibility() {
    // Show or hide both the remote and on-site options depending on the selected value
    const remoteOptions = ['remote_only', 'primarily_remote', 'either_remote_or_onsite', 'primarily_onsite']
    this.remoteOptionsTarget.hidden = remoteOptions.includes(this.locationKindTarget.value) == false

    const onsiteOptions = ['primarily_remote', 'either_remote_or_onsite', 'primarily_onsite', 'onsite_only']
    this.onsiteOptionsTarget.hidden = onsiteOptions.includes(this.locationKindTarget.value) == false
  }

  updateStatesVisibility() {
    // Get all selected countries, as an array of their values
    const selectedOptions = this.countryIdsTarget.querySelectorAll('option:checked')
    const selectedCountries = Array.from(selectedOptions).map((element) => element.value)

    // Show or hide the state field depending on whether USA is included
    this.statesTarget.hidden = selectedCountries.includes('USA') == false
  }

  descriptionDidChange() {
    this.removeLineBreaks(this.descriptionTarget)
    this.updateCharacterCounter(this.descriptionTarget, this.descriptionCounterTarget)
  }

  socialTextDidChange() {
    this.updateCharacterCounter(this.socialTextTarget, this.socialTextCounterTarget)
  }

  // MARK: - Helpers

  updateCharacterCounter(fieldElement, counterElement) {
    const currentLength = fieldElement.value.length
    const maximumLength = fieldElement.dataset.maximumLength
    counterElement.innerText = `${currentLength} of ${maximumLength}`

    // Should a class be applied?
    counterElement.classList.remove('counter--warning')
    counterElement.classList.remove('counter--danger')
    if (currentLength > maximumLength) {
      counterElement.classList.add('counter--danger')
    } else if (currentLength > maximumLength * 0.9) {
      counterElement.classList.add('counter--warning')
    }
  }

  removeLineBreaks(fieldElement) {
    fieldElement.value = fieldElement.value.replace(/\r?\n|\r/g, '')
  }

  scrollToFirstError() {
    const errorMessageElement = this.element.querySelector('.error_message')
    if (!errorMessageElement) return

    setTimeout(() => {
      errorMessageElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 1)
  }
}
