//
// Element Scroll Suspension
//
// Adds the 'overflow: hidden' style to prevent an element from being scrolled.
//
// Several components on a page may want a single element to be non-scrollable (e.g. multiple layered overlays).
// Using this class fixes premature scroll restoration by maintaining a count of how many components want an element's
// scrolling behaviour to be suspended (similar to the ARC feature in Objective-C).
//

export class ElementScrollSuspension {
  // MARK: - Object Lifecycle

  constructor(element = document.body || document.documentElement) {
    this.element = element
    this.suspended = false
  }

  // MARK: - Getters

  get count() {
    const value = this.element.getAttribute('data-scroll-suspension')
    if (typeof value !== 'string' || value.length < 1) return 0

    return parseInt(value)
  }

  // MARK: - Actions

  suspend() {
    if (this.suspended) return

    this.suspended = true
    this.element.setAttribute('data-scroll-suspension', `${this.count + 1}`)
  }

  unsuspend() {
    if (!this.suspended) return

    this.suspended = false
    if (this.count > 1) {
      this.element.setAttribute('data-scroll-suspension', `${this.count - 1}`)
    } else {
      this.element.removeAttribute('data-scroll-suspension')
    }
  }
}
