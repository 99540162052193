//
// Payment Controller
//

import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'
import * as Turbo from '@hotwired/turbo'
import { ajax } from '../lib/ajax'
import { ActivityOverlay } from '../lib/activity_overlay'

export class PaymentController extends Controller {
  static targets = ['loading', 'stripeButton', 'paypalButton', 'submit', 'error', 'cardNumber', 'cardExpiry', 'cardCVC']

  // MARK: - Callbacks

  initialize() {
    this.activityOverlay = new ActivityOverlay()
  }

  connect() {
    this.setupStripe()
    this.setupPayPal()
  }

  disconnect() {
    this.tearDownStripe()
    this.tearDownPayPal()
  }

  // MARK: - Form Submission

  pay(event) {
    event.preventDefault()

    this.setActive(true)
    this.errorTarget.innerText = ''

    if (event.submitter.getAttribute('data-payment-method') === 'invoice') {
      this.createInvoicePayment(event)
    } else {
      this.createCardPayment(event)
    }
  }

  // MARK: - Card / Invoice Payments

  createCardPayment(event) {
    this.createPayment('stripe', 'card')
      .then((result) => {
        this.stripe
          .confirmCardPayment(result.data.client_secret, {
            payment_method: {
              card: this.cardNumberElement,
            },
          })
          .then((result) => {
            if (result.error) {
              this.setError(result.error.message)
            } else {
              this.onPaid()
            }
          })
          .catch((error) => this.setError(error))
      })
      .catch((error) => this.setError(error))
  }

  createInvoicePayment(event) {
    this.createPayment('manual', 'invoice')
      .then((result) => this.onPaid())
      .catch((error) => this.setError(error))
  }

  // MARK: - Helpers

  setActive(active) {
    this.activityOverlay.setVisible(active)
    this.activityOverlay.setSpinnerVisible(active)
  }

  setError(error) {
    if (typeof error === 'string') {
      this.errorTarget.innerText = error
    } else {
      console.error(error)

      if (error.response) {
        this.errorTarget.innerText = ''

        error.response.forEach((error) => {
          const paragraphElement = document.createElement('p')
          paragraphElement.innerText = error
          this.errorTarget.appendChild(paragraphElement)
        })
      } else {
        this.errorTarget.innerText = error.message
      }
    }

    if (this.errorTarget.childElementCount > 0 || this.errorTarget.innerText.length > 0) {
      this.errorTarget.scrollIntoView({ block: 'center' })
    }

    this.submitTarget.removeAttribute('disabled')
    this.setActive(false)
  }

  createPayment(gateway, sourceType) {
    const formData = new FormData()
    formData.append('gateway', gateway)
    formData.append('source_type', sourceType)

    return ajax('post', this.element.action, null, formData)
  }

  verifyPayment() {
    return ajax('patch', this.element.action)
  }

  // MARK: - Events

  onPaid() {
    this.verifyPayment()
      .then((result) => {
        if (result.data.location) {
          Turbo.clearCache()
          Turbo.visit(result.data.location, { action: 'replace' })
        } else {
          this.setError('Your payment is complete, please refresh the page to continue.')
        }
      })
      .catch((error) => this.setError(error))
  }

  // MARK: - Stripe

  get stripePublishableKey() {
    return document.querySelector('meta[name="stripe-publishable-key"]').content
  }

  get styleForStripeCard() {
    return {
      base: {
        color: '#495057',
        fontFamily: "'Merriweather', Georgia, Times, 'Times New Roman', serif",
        fontSize: '16px',
        fontSmoothing: 'antialiased',
      },
    }
  }

  get styleForStripePaymentRequestButton() {
    return {
      paymentRequestButton: {
        height: '48px',
      },
    }
  }

  setupStripe() {
    loadStripe(this.stripePublishableKey).then((stripe) => {
      this.stripe = stripe
      this.stripeElements = stripe.elements()

      // First, setup the manual credit/debit card fields.
      this.cardNumberElement = this.stripeElements.create('cardNumber', { style: this.styleForStripeCard })
      this.cardNumberElement.mount(this.cardNumberTarget)

      this.cardExpiryElement = this.stripeElements.create('cardExpiry', { style: this.styleForStripeCard })
      this.cardExpiryElement.mount(this.cardExpiryTarget)

      this.cardCVCElement = this.stripeElements.create('cardCvc', { style: this.styleForStripeCard })
      this.cardCVCElement.mount(this.cardCVCTarget)

      // Next, create a payment request and associated button to handle Apple Pay, Google Pay, etc.
      this.paymentRequest = stripe.paymentRequest({
        country: this.data.get('country').toUpperCase(),
        currency: this.data.get('currency').toLowerCase(),
        total: {
          label: this.data.get('description'),
          amount: parseInt(this.data.get('amount')),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: false,
      })

      this.paymentRequest.on('paymentmethod', (event) => {
        this.createPayment('stripe', 'button')
          .then((result) => {
            const clientSecret = result.data.client_secret

            stripe
              .confirmCardPayment(clientSecret, { payment_method: event.paymentMethod.id }, { handleActions: false })
              .then((result) => {
                if (result.error) {
                  event.complete('fail')
                  this.setError(result.error)
                } else {
                  event.complete('success')

                  if (result.paymentIntent.status === 'requires_action') {
                    stripe.confirmCardPayment(clientSecret).then((result) => {
                      if (result.error) {
                        this.setError(result.error)
                      } else {
                        this.onPaid()
                      }
                    })
                  } else {
                    this.onPaid()
                  }
                }
              })
              .catch((error) => {
                event.complete('fail')
                this.setError(error)
              })
          })
          .catch((error) => {
            event.complete('fail')
            this.setError(error)
          })
      })

      this.paymentRequest.canMakePayment().then((result) => {
        if (!result) return

        this.paymentRequestButton = stripe.elements().create('paymentRequestButton', {
          paymentRequest: this.paymentRequest,
          style: this.styleForStripePaymentRequestButton,
        })

        this.paymentRequestButton.mount(this.stripeButtonTarget)
      })
    })
  }

  tearDownStripe() {
    if (this.cardNumberElement) {
      this.cardNumberElement.destroy()
      delete this.cardNumberElement
    }

    if (this.cardExpiryElement) {
      this.cardExpiryElement.destroy()
      delete this.cardExpiryElement
    }

    if (this.cardCVCElement) {
      this.cardCVCElement.destroy()
      delete this.cardCVCElement
    }

    if (this.paymentRequestButton) {
      this.paymentRequestButton.destroy()
      delete this.paymentRequestButton
    }

    if (this.paymentRequest) {
      delete this.paymentRequest
    }
  }

  // MARK: - PayPal

  get paypalClientID() {
    return document.querySelector('meta[name="paypal-client-id"]').content
  }

  get styleForPayPal() {
    return {
      height: 48,
    }
  }

  setupPayPal() {
    const action = this.data.get('action').toLowerCase()
    const frameElement = document.createElement('iframe')
    frameElement.style.display = 'block'
    frameElement.style.width = '0'
    frameElement.style.height = '0'
    frameElement.frameBorder = '0'
    frameElement.scrolling = 'no'
    frameElement.src = '/paypal-iframe.html'
    frameElement.addEventListener('load', () => {
      frameElement.contentWindow.loadPayPal(
        {
          'client-id': this.paypalClientID,
          'disable-funding': 'bancontact,blik,card,credit,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo',
          currency: 'USD',
          intent: action,
        },
        {
          style: this.styleForPayPal,
          createOrder: () => {
            this.errorTarget.innerText = ''

            return this.createPayment('paypal', 'button').then((result) => {
              return result.data.id
            })
          },
          onApprove: (_data, actions) => {
            if (action === 'authorize') {
              return actions.order.authorize().then(() => this.onPaid())
            } else {
              return actions.order.capture().then(() => this.onPaid())
            }
          },
          onError: (error) => {
            this.setError(error)
          },
        }
      )
    })

    this.paypalButtonTarget.appendChild(frameElement)
    this.paypalIFrameElement = frameElement
  }

  tearDownPayPal() {
    if (this.paypalIFrameElement) {
      this.paypalIFrameElement.remove()
      delete this.paypalIFrameElement
    }
  }
}
