//
// Patches / Choices
//

import Choices from 'choices.js'

// Turbo cache requires us to remove the custom Choices elements, otherwise we'd navigate back to a broken page.
// Choices `destroy` method does not retain the user's selections so while the page now wouldn't be broken, the form
// is no longer as the user left it. This custom implementation both destroys and preserves the selections to fix this.
Choices.prototype.prepareForTurboCache = function () {
  let values = this.getValue(true)
  if (!Array.isArray(values)) values = [values]

  this.destroy()

  const selectElement = this.passedElement.element
  const optionElements = selectElement.querySelectorAll('option')
  Array.from(optionElements).forEach((optionElement) => {
    if (values.includes(optionElement.value)) {
      optionElement.setAttribute('selected', 'selected')
    } else {
      optionElement.removeAttribute('selected')
    }
  })
}
