//
// Preview Job Listing Controller
//

import { Controller } from '@hotwired/stimulus'

export class PreviewJobListingController extends Controller {
  static targets = ['title', 'promotionKind']

  // MARK: - Callbacks

  connect() {
    this.updatePromotionKind()

    setTimeout(() => {
      this.element.classList.add('transitionable')
    }, 0)
  }

  // MARK: - Actions

  updatePromotionKind(event = null) {
    // Determine the chosen promotion kind from the selected radio element.
    const radioElement = this.promotionKindTargets.find((element) => element.checked)
    const promotionKind = radioElement.value

    // Ensure the chosen promotion kind is selected.
    this.promotionKindTargets.forEach((element) => {
      if (element.checked || element.value !== promotionKind) return

      element.checked = true
    })

    // Show the extras div for the chosen promotion kind.
    this.removeElementClasses(this.element, /^listing-type--/)
    this.element.classList.add(`listing-type--${promotionKind}`)

    // Update the title of the card element.
    this.titleTarget.innerText = `${promotionKind[0].toUpperCase() + promotionKind.substring(1)} Listing`

    // Remove the error from the page.
    if (event) this.element.querySelector('.error_message').remove()
  }

  removeElementClasses(element, pattern) {
    Array.from(element.classList).forEach((className) => {
      if (!className.match(pattern)) return

      element.classList.remove(className)
    })
  }
}
