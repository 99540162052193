import { Controller } from '@hotwired/stimulus'
import { ElementScrollSuspension } from '../lib/element_scroll_suspension'

export class ModalController extends Controller {
  static targets = ['button', 'modal']

  // MARK: - Controller Lifecycle

  initialize() {
    this.buttonTarget.classList.add('modal__toggle--show')
    this.elementScrollSuspension = new ElementScrollSuspension()
  }

  disconnect() {
    this.hide()
  }

  // MARK: - Actions

  show() {
    if (this.modalTarget.classList.contains('modal--show')) return

    this.modalTarget.classList.add('modal--show')
    this.elementScrollSuspension.suspend()
  }

  hide() {
    if (!this.modalTarget.classList.contains('modal--show')) return

    this.modalTarget.classList.remove('modal--show')
    this.elementScrollSuspension.unsuspend()
  }

  backgroundHide(event) {
    if (!(event.target instanceof HTMLElement) || !event.target.matches('.modal')) return

    this.hide()
  }

  beforeStreamRender(event) {
    // Is the rendered form dismissing the modal?
    if (event.srcElement.templateContent.querySelector('[data-modal-dismiss]')) this.hide()
  }
}
