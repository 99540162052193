//
// Application / Controllers
//

import { Application } from '@hotwired/stimulus'
import { BulkMailController } from './bulk_mail_controller'
import { CompanyInformationController } from './company_information_controller'
import { DashboardController } from './dashboard_controller'
import { EditJobListingController } from './edit_job_listing_controller'
import { JobListingConfigurationController } from './job_listing_configuration_controller'
import { ManageJobListingController } from './manage_job_listing_controller'
import { ModalController } from './modal_controller'
import { PaymentController } from './payment_controller'
import { PreviewJobListingController } from './preview_job_listing_controller'
import { SendAgainController } from './send_again_controller'

const application = Application.start()
application.register('bulk-mail', BulkMailController)
application.register('company-information', CompanyInformationController)
application.register('dashboard', DashboardController)
application.register('edit-job-listing', EditJobListingController)
application.register('job-listing-configuration', JobListingConfigurationController)
application.register('manage-job-listing', ManageJobListingController)
application.register('modal', ModalController)
application.register('payment', PaymentController)
application.register('preview-job-listing', PreviewJobListingController)
application.register('send-again', SendAgainController)
