//
// Send Again Controller
//

import { Controller } from '@hotwired/stimulus'

export class SendAgainController extends Controller {
  static targets = ['card']

  // MARK: - Callbacks

  connect() {
    if (this.hasCardTarget) this.cardTarget.style.display = 'none'
  }

  // MARK: - Actions

  toggleCard(event) {
    event.preventDefault()

    if (this.cardTarget.style.display === 'none') {
      this.cardTarget.style.display = ''
    } else {
      this.cardTarget.style.display = 'none'
    }
  }
}
